<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Bulk printer queue</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Bulk printer queue
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar"></div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12">
                  <div class="breadcrumb pt-3 pb-0 d-flex justify-space-between">
                    <ul v-if="userNotification">
                      <li class="list-style-none"> <strong> Print job title</strong>: {{userNotification.title}} </li>
                      <li class="list-style-none"> <strong> Created by</strong>: {{userNotification.user.full_name}} </li>
                      <li class="list-style-none"> <strong> Created at</strong>: {{userNotification.formatted_date_sent}}</li>
                    </ul>
                  </div>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                    color="red"
                    class="text-white ml-3"
                    medium
                    @click="deleteNotification"
                    :loading="isDeleting"
                  >
                    Remove this print job
                  </v-btn>
                </v-col>

                <v-col cols="12" md="10" class="text-right">
                  <v-btn
                    color="red"
                    class="text-white ml-3"
                    medium
                    :loading="isPrintJobDeleting"
                    v-if="printJobs.length > 0 "
                    @click="deletePrintJobs"
                    :disabled="print_job_ids.length == 0"
                  >
                   Remove from list
                  </v-btn>

                  <v-btn
                    color="orange"
                    class="text-white ml-3"
                    medium
                    :loading="printLabelLoading"
                    v-if="printJobs.length > 0 && printJobs[0].type == 'certificate'"
                    @click="printLabels"
                    :disabled="print_job_ids.length == 0"
                  >
                    Print label
                  </v-btn>

                  <v-btn
                    color="blue"
                    class="text-white ml-3"
                    medium
                    v-if="
                      printJobs.length > 0 && printJobs[0].type == 'certificate'
                    "
                    :loading="isMarkAsDispatchedLoading"
                    @click="markAsDispatched"
                    :disabled="print_job_ids.length == 0"
                  >
                    Mark as sent
                  </v-btn>

                  <v-btn
                    color="success"
                    class="text-white ml-3"
                    medium
                    :loading="isMarkAsCompleteLoading"
                    @click="markAsComplete"
                    :disabled="print_job_ids.length == 0"
                  >
                    Mark as printed
                  </v-btn>
                </v-col>

                <v-col cols="12" md="12" class="text-right">
                  <div class="">
                    <v-skeleton-loader type="table-thead" v-if="loading">
                    </v-skeleton-loader>

                    <v-skeleton-loader
                      v-if="loading"
                      type="table-row-divider@25"
                    >
                    </v-skeleton-loader>

                    <table class="table" v-if="!loading">
                      <thead>
                        <tr class="text-left">
                          <th class="px-3">
                            <v-checkbox
                              v-if="showCheckAllBox"
                              @click="checkAllPrintJob"
                              v-model="checkAllPrintJobFlag"
                            ></v-checkbox>
                          </th>
                          <th class="px-3" v-if="!isLabel" style="max-width: 120px !important; white-space: pre-wrap;">Filename</th>
                          <th class="px-3" v-if="!isLabel" style="max-width: 120px !important; white-space: pre-wrap;">Type</th>
                          <th class="px-3" v-if="!isLabel" style="max-width: 150px;white-space: pre-wrap; ">Status </th>
                          <th class="px-3">Action </th>
                      </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr
                            v-if="!isLabel"
                            v-for="(item, index) in printJobs"
                            :key="index"
                          >
                            <td class="px-2">
                              <v-checkbox
                                v-model="print_job_ids"
                                :value="item.id"
                              ></v-checkbox>
<!--                              <span-->
<!--                                class="ml-4 pl-2 badge badge-success"-->
<!--                                v-if="item.is_completed"-->
<!--                              >-->
<!--                                Marked as complete-->
<!--                              </span>-->
                            </td>



                            <td style="max-width: 300px;white-space: pre-wrap; ">
                              <div
                                style="
                                  max-width: 300px !important;
                                  white-space: pre-wrap;
                                "
                              >
                                {{ item.file_name }}
                              </div>
                            </td>

                            <td>
                              {{item.type}}
                            </td>



                            <td style="max-width: 150px;white-space: pre-wrap; ">
                              <span
                                  class="mb-5 badge badge-success"
                                  v-if="item.is_completed"
                              >
                                  Printed
                              </span>

                              <span
                                class="mb-5 badge badge-warning"
                                v-if="!item.is_completed"
                              >
                                Not printed
                              </span>
                              <br/>


                            </td>


                            <td>
                              <v-btn
                                color="success"
                                small
                                class="text-white"
                                @click="viewFile(item.file_path)"
                              >
                                Preview
                              </v-btn>
                              <br>
                              <v-btn
                                v-if="item.backview_url"
                                color="primary"
                                small
                                class="text-white mt-2"
                                @click="viewFile(item.backview_url)"
                              >
                                Preview back view
                              </v-btn>
                            </td>
                          </tr>

                          <tr v-if="isLabel">
                            <td class="px-2">
                              <v-checkbox
                                v-if="!printJobs[0].is_completed"
                                v-model="print_job_ids"
                                :value="printJobs[0].id"
                              ></v-checkbox>
                              <span
                                class="ml-4 pl-2 badge badge-success"
                                v-if="printJobs[0].is_completed"
                              >
                                Mark as complete
                              </span>
                            </td>
                            <td>
                              <span class="font-size-lg">
                                {{ printJobs[0].file_name }}
                              </span>
                            </td>
                            <td>
                              <v-btn
                                color="success"
                                small
                                class="text-white"
                                @click="viewFile(printJobs[0].file_path)"
                              >
                                <v-icon small elevation="2" outlined
                                  >fas fa-eye</v-icon
                                >&nbsp; View
                              </v-btn>
                            </td>
                          </tr>
                          <tr v-if="printJobs.length == 0">
                            <td colspan="4" class="text-center">
                              Data not available
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>

                    <b-pagination
                      v-if="printJobs.length > 0"
                      class="pull-right mt-7"
                      @input="getPrintFiles"
                      v-model="page"
                      :total-rows="total"
                      :disabled="loading"
                      :per-page="perPage"
                      first-number
                      last-number
                    ></b-pagination>
                  </div>
                </v-col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import { required } from "vuelidate/lib/validators";
import NotificationService from "@/core/services/notification/NotificationService";
import PrintJobService from "@/core/services/printer/PrintJobService";

const notificationService = new NotificationService();
const printJobService = new PrintJobService();


export default {
  validations: {
    print_job_ids: { required },
  },
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isLabel: false,
      printJobs: [],
      print_job_ids: [],
      checkAllPrintJobFlag: false,
      isMarkAsCompleteLoading: false,
      isMarkAsDispatchedLoading: false,
      userNotification: '',
      printLabelLoading: false,
      isDeleting: false,
      isPrintJobDeleting: false
    };
  },
  computed: {
    showCheckAllBox() {
      let completedValueExist = this.printJobs.filter((item) => {
        return item.is_completed == 0;
      });
      if (completedValueExist.length == 0) return false;
      return true;
    },
  },
  methods: {
    checkAllPrintJob() {
      if (this.checkAllPrintJobFlag) {
        this.printJobs.forEach((item) => {
          this.print_job_ids.push(item.id);
        });
      } else {
        this.print_job_ids = [];
      }
    },
    markNotificationAsRead() {
      userNotification
        .markAsViewed(this.userNotificationId)
        .then((response) => {})
        .catch((err) => {});
    },
    checkIfPrintJobTypeIsLabel() {
      if (this.printJobs && this.printJobs.length > 0) {
        if (this.printJobs[0].type == "label") {
          this.isLabel = true;
        }
      }
    },
    getPrintFiles() {
      this.loading = true;
      notificationService
        .getPrintFiles( this.page,{}, this.userNotificationId)
        .then((response) => {
          this.printJobs = response.data.jobs;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
          this.markNotificationAsRead();

        })
        .catch((err) => {
          this.loading = false;
        })
        .finally(() => {});
    },
    viewFile(filePath) {
      window.open(filePath, "_blank");
    },
    markAsComplete() {
      let data = "";
      if (this.isLabel) {
        data = {
          user_notification_id: this.printJobs[0].user_notification_id,
          type: "label",
        };
      } else {
        data = {
          print_job_ids: this.print_job_ids,
          user_notification_id: this.printJobs[0].user_notification_id,
          type: "label",
        };
      }

      if (this.$v.$error) {
        this.$snotify.error("Minimum of one file is required to continue");
      } else {
        this.isMarkAsCompleteLoading = true;
        printJobService
          .markAsCompleted(data)
          .then((response) => {
            this.$snotify.success(response.data.message);
            this.isMarkAsCompleteLoading = false;
            this.print_job_ids = [];
            this.checkAllPrintJobFlag = false;
            this.isLabel = false;
            this.printJobs = [];
            this.getPrintFiles();
          })
          .catch((err) => {
            this.$snotify.error("Oops looks like something went wrong.");
            this.isMarkAsCompleteLoading = false;
          })
          .finally(() => {});
      }
    },

    showUserNotification(){
      notificationService
      .show(this.userNotificationId)
      .then((res) => {
        this.userNotification = res.data.notification;
      })
    },




    deleteNotification(){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isDeleting = true;

            notificationService
            .delete(this.userNotificationId)
            .then((res) => {
              this.$snotify.success('Notification deleted');
              this.$tabs.close();
            })
            .catch((err) => {
              this.$snotify.success('Oops looks like something went wrong.');
            })
            .finally(() => {
              this.isDeleting = false;
            })
          }
        },
      })
    },
    deletePrintJobs(){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isPrintJobDeleting = true;
            let data ={
              'job_ids':this.print_job_ids
            };
            printJobService
            .deleteMultiple(data)
            .then((res) => {
              this.$snotify.success('Prints deleted from notification');
              this.showUserNotification();
              this.getPrintFiles();
              this.print_job_ids = [];
            })
            .catch((err) => {
              this.$snotify.error('Oops looks like something went wrong.');
            })
            .finally(() => {
              this.isPrintJobDeleting = false;
            })
          }
        },
      })
    }
  },
  mounted() {
    this.userNotificationId = this.$route.params.userNotificationId;
    this.getPrintFiles();
    this.showUserNotification();
  },
};
</script>

<style scoped>
  .list-style-none{
    list-style: none;
  }
</style>
